<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="mensajes"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <EditarMensaje :mensaje="item" />
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import EditarMensaje from '../../components/utils/EditarMensaje.vue'

export default {
  data() {
    return {
      dialog: false,
      editar: false,
      load: false,
      headers: [
        { text: 'Nombre', value: 'nombre'},
        { text: 'Detalle', value: 'actions', align: 'center', sortable: false },
      ],
      mensajes: [],
    }
  },
  async created() {
    this.load = true
    this.mensajes = []
    await db.collection('mensajes_predefinidos').get()
      .then((res) => {
        res.forEach((doc) => {
          let mensaje = doc.data()
          mensaje.id = doc.id
          this.mensajes.push(mensaje)
        })
      })
      .catch((error) => {
        console.log(error)
      })
    this.load = false
  },
  components: {
    EditarMensaje
  },
}
</script>