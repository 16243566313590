<template>
  <v-dialog
    :persistent="editar"
    v-model="dialog"
    max-width="850px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        color="info"
        v-bind="attrs"
        v-on="on"
      >
        fas fa-eye
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex">
        <span v-if="editar" class="text-h5">Editar Mensaje</span>
        <span v-else class="text-h5">Detalle</span>
        <v-spacer></v-spacer>
        <v-switch
          v-model="editar"
          label="Editar"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" md="5">
              <v-text-field
                class="mt-1"
                :readonly="!editar"
                :filled="!editar"
                v-model.trim="mensaje.nombre"
                :rules="[rules.required]"
                label="Nombre"
                validate-on-blur
              ></v-text-field>
              <v-textarea
                :readonly="!editar"
                :filled="!editar"
                v-model.trim="mensaje.detalle"
                :rules="[rules.required]"
                label="Descripción"
                rows="5"
                auto-grow
                validate-on-blur
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="7">
              <v-card class="mt-1">
                <v-card-title class="text-h6 font-weight-light py-2">Notificación que recibe el postulante</v-card-title>
                <v-card-text>
                  <v-text-field
                    :readonly="!editar"
                    :filled="!editar"
                    v-model.trim="mensaje.titulo"
                    :rules="[rules.required]"
                    label="Título"
                    validate-on-blur
                  ></v-text-field>
                  <v-textarea
                    :readonly="!editar"
                    :filled="!editar"
                    v-model.trim="mensaje.texto"
                    :rules="[rules.required]"
                    label="Mensaje"
                    rows="3"
                    auto-grow
                    validate-on-blur
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn v-if="!editar" color="accent" @click="dialog = false">Volver</v-btn>
        <div v-else>
          <v-btn
            color="error"
            class="mr-4"
            :disabled="load"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar :loading="load" @action="save"/>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import Confirmar from '../../components/utils/Confirmar.vue'

export default {
  data() {
    return {
      dialog: false,
      editar: false,
      load: false,
      rules: { required: value => !!value || 'Campo requerido' },
    }
  },
  props: {
    mensaje: Object
  },
  components: {
    Confirmar
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    async save () {
      if (this.$refs.form.validate()) {
        this.load = true
        await db.collection('mensajes_predefinidos').doc(this.mensaje.id)
          .update({
            detalle: this.mensaje.detalle,
            nombre: this.mensaje.nombre,
            titulo: this.mensaje.titulo,
            texto: this.mensaje.texto,
          })
          .then(() => {
            //
          })
          .catch((error) => {
            console.error(error)
          })
        this.load = false
        this.dialog = false
      }
    },
    async close () {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.editar = false
      this.$refs.form.resetValidation()
    }
  },
}
</script>